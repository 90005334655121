import { createContext, useState, useEffect, ReactNode, useContext, useCallback, useRef } from "react"
import { CurrenciesAllowedEnum } from "@tutellus/core-contracts-ifaces"
import { ErrorModal, ErrorModalProps } from "../components/modules/Modals/ErrorModal"
import { DomainEventDetail, DomainEvents } from "../domain/_kernel/Events"
import { Toaster, ToasterHandle, ToastProps } from "@tutellus/tutellus-components"
import { useTranslation } from "react-i18next"
import { TransactionStatus, TransactionType } from "../domain/ethereum/Models/Transaction"
import { ErrorCodes } from "../domain/_kernel/ErrorCodes"
import { SocialModal, SocialModalProps } from "../components/modules/Modals/SocialModal"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { isLastElementInAnyArray } from "../js/array"
import { ProjectConfig } from "../domain/project/Models/ProjectConfig"

const THEME_MODE_STORAGE_KEY = "theme-mode"
export const CURRENCY_STORAGE_KEY = "currency"
export const FIRST_NFT_GENERATED = "first-nft-generated"
export const FIRST_VOTE = "first-vote"
const projectToken = import.meta.env.VITE_PROJECT_KEY

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export enum ModalTypes {
  ERROR = "ERROR",
  SOCIAL = "SOCIAL",
}

export type ModalType = (typeof ModalTypes)[keyof typeof ModalTypes]

export interface ModalConfigMap {
  [ModalTypes.ERROR]: {
    component: typeof ErrorModal
    props: ErrorModalProps
  }
  [ModalTypes.SOCIAL]: {
    component: typeof SocialModal
    props: SocialModalProps
  }
}

interface ModalInstance<T extends ModalType = ModalType> {
  type: T
  props: T extends keyof ModalConfigMap ? Omit<ModalConfigMap[T]["props"], "onClose"> : never
}

interface LayoutContextInterface {
  themeMode: ThemeMode
  setThemeMode: (mode: ThemeMode) => void
  currencyID: CurrenciesAllowedEnum
  setCurrencyID: (currency: CurrenciesAllowedEnum) => void
  addToast: (toast: Omit<ToastProps, "id" | "isExiting" | "onClose"> & { duration?: number }) => void
  removeToast: (id: string) => void
  clearAllToasts: () => void
}

const initialState: LayoutContextInterface = {
  themeMode: ThemeMode.LIGHT,
  setThemeMode: () => {},
  currencyID: CurrenciesAllowedEnum.USD,
  setCurrencyID: () => {},
  addToast: () => {},
  removeToast: () => {},
  clearAllToasts: () => {},
}

interface LayoutProviderProps {
  children: ReactNode
}

const Context = createContext<LayoutContextInterface>(initialState)

export const LayoutContext = ({ children }: LayoutProviderProps) => {
  const [projectConfig, setProjectConfig] = useState<ProjectConfig | null>(null)
  const [themeMode, setThemeMode] = useLocalStorage<ThemeMode>(THEME_MODE_STORAGE_KEY, ThemeMode.LIGHT)
  const [isFirstNFT, setIsFirstNFT] = useLocalStorage(FIRST_NFT_GENERATED, false)
  const [isFirstVote, setIsFirstVote] = useLocalStorage(FIRST_VOTE, false)
  const [currencyID, setCurrencyID] = useLocalStorage<CurrenciesAllowedEnum>(CURRENCY_STORAGE_KEY,CurrenciesAllowedEnum.USD) // prettier-ignore
  const [activeModal, setActiveModal] = useState<ModalInstance | null>(null)
  const toasterRef = useRef<ToasterHandle>(null)
  const { t } = useTranslation("common")

  const updateProjectConfig = () =>
    window.domain.GetConfigProjectUseCase.execute().then(setProjectConfig).catch(console.error)

  useEffect(() => {
    updateProjectConfig()
  }, [])

  const addToast = useCallback((toast: Omit<ToastProps, "id" | "isExiting" | "onClose"> & { duration?: number }) =>toasterRef.current?.addToast({ ...toast, onClose: () => {} }),[]) // prettier-ignore
  const removeToast = useCallback((id: string) => toasterRef.current?.removeToast(id), [])
  const clearAllToasts = useCallback(() => toasterRef.current?.clearAllToasts(), [])

  const openModal = <T extends keyof ModalConfigMap>(type: T, props: Omit<ModalConfigMap[T]["props"], "onClose">) =>
    setActiveModal({ type, props })

  const closeModal = () => setActiveModal(null)

  const handleFinishedTransaction = useCallback(
    (e: CustomEvent<DomainEventDetail[DomainEvents.SUCCESS_TRANSACTION | DomainEvents.REVERTED_TRANSACTION]>) => {
      const { explorerUrl, type, status, args } = e.detail.transaction.serialize()
      const projectTokenAddress = projectConfig?.addresses.find(({ tokenID }) => tokenID === projectToken)?.address

      switch (type) {
        case TransactionType.DEPOSIT_STAKE:
          if (isFirstNFT) break
          openModal(ModalTypes.SOCIAL, { variant: "generateFirstNFT" })
          setIsFirstNFT(true)
          break
        case TransactionType.VOTE:
          if (isFirstVote) break
          openModal(ModalTypes.SOCIAL, { variant: "voteFirstTime" })
          setIsFirstVote(true)
          break
        case TransactionType.SWAP: {
          const isProjectTokenSwap = isLastElementInAnyArray(args ?? [], projectTokenAddress)
          if (isProjectTokenSwap) openModal(ModalTypes.SOCIAL, { variant: "swapDexToken" })
          break
        }
      }

      addToast({
        message: t(`transactions.types.${type}`),
        onButtonClick: () => window.open(explorerUrl?.toString(), "_blank"),
        buttonText: t("view"),
        variant: status === TransactionStatus.SUCCESS ? "success" : "error",
        duration: 10000,
      })
    },
    [addToast, isFirstNFT, isFirstVote, projectConfig?.addresses, setIsFirstNFT, setIsFirstVote, t],
  )

  const handleDomainError = useCallback((e: CustomEvent<DomainEventDetail[DomainEvents.ERROR]>) => {
    switch (e.detail.domainError.code) {
      case ErrorCodes.SIGNATURE_ERROR:
      case ErrorCodes.TRANSACTION_ERROR:
        openModal(ModalTypes.ERROR, { error: e.detail.domainError.error })
        break
    }
  }, [])

  // Apply theme to root element
  useEffect(() => {
    const rootElement = document.getElementById("root")
    if (rootElement) {
      rootElement.classList.remove(ThemeMode.LIGHT, ThemeMode.DARK)
      rootElement.classList.add(themeMode)
    }
  }, [themeMode])

  // Currency

  useEffect(() => {
    window.addEventListener(DomainEvents.SUCCESS_TRANSACTION, handleFinishedTransaction as EventListener)
    window.addEventListener(DomainEvents.REVERTED_TRANSACTION, handleFinishedTransaction as EventListener)
    window.addEventListener(DomainEvents.ERROR, handleDomainError as EventListener)

    return () => {
      window.removeEventListener(DomainEvents.SUCCESS_TRANSACTION, handleFinishedTransaction as EventListener)
      window.removeEventListener(DomainEvents.REVERTED_TRANSACTION, handleFinishedTransaction as EventListener)
      window.removeEventListener(DomainEvents.ERROR, handleDomainError as EventListener)
    }
  }, [handleDomainError, handleFinishedTransaction])

  const renderModal = () => {
    if (!activeModal) return null

    switch (activeModal.type) {
      case ModalTypes.ERROR:
        return <ErrorModal {...(activeModal.props as Omit<ErrorModalProps, "onClose">)} onClose={closeModal} />
      case ModalTypes.SOCIAL:
        return <SocialModal {...(activeModal.props as Omit<SocialModalProps, "onClose">)} onClose={closeModal} />
    }
  }

  return (
    <Context.Provider
      value={{
        themeMode,
        setThemeMode,
        currencyID,
        setCurrencyID,
        addToast,
        removeToast,
        clearAllToasts,
      }}
    >
      {renderModal()}
      <Toaster ref={toasterRef} />
      {children}
    </Context.Provider>
  )
}

export const useLayout = function () {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error(`useLayout must be used within a LayoutContextProvider`)
  }
  return context
}
