import { redirect } from "react-router"
import { WalletState } from "../../domain/ethereum/Models/WalletState"
import { isAppInstalled } from "../../js/pwa"
import { SHOW_PWA_INFO } from "../../js/const"

export const pwaLoader = async () => {
  const wallet = await window.domain.GetWalletStateEthereumUseCase.execute()

  if (wallet.type === WalletState.TYPES.INJECTED) return redirect("/")
  if (wallet.type === WalletState.TYPES.ABSTRACTED) {
    const showPWAInfo = window.localStorage.getItem(SHOW_PWA_INFO) === "false" ? false : true
    if (showPWAInfo === false) return redirect("/")
    const isInstalled = isAppInstalled()
    if (isInstalled) return redirect("/")
  }
  return null
}
