import { sepolia } from "viem/chains";
export const SEPOLIA = {
    id: sepolia.id,
    name: sepolia.name,
    logo: "https://chainlist.org/unknown-logo.png",
    explorer: "https://sepolia.etherscan.io",
    isTestnet: true,
    pollingInterval: 5_000,
    viemConfig: sepolia,
};
