import { useTranslation } from "react-i18next"
import {
  AddressBox,
  Button,
  buttonTypes,
  Modal,
  WalletIcon,
  DocIcon,
  TokenIcon,
  BagIcon,
} from "@tutellus/tutellus-components"
import { useNavigate, useRouteLoaderData } from "react-router"
import { useEthereum } from "../../../context"
import { WalletState } from "../../../domain/ethereum/Models/WalletState"
import { useFormatAmounts } from "../../../hooks/useFormatAmounts"
import { RootDataLoader } from "../../../pages/DataRoot/loader"
import { getExplorerTokenURL } from "../../../js/chain"
import styles from "./index.module.css"

const PROJECT_KEY = import.meta.env.VITE_PROJECT_KEY

interface TokenModalProps {
  onClose: () => void
}

export const TokenModal = ({ onClose }: TokenModalProps) => {
  const { t } = useTranslation("common")
  const { projectConfig, projectTokenPrices } = useRouteLoaderData("root") as RootDataLoader
  const { wallet } = useEthereum()
  const { formatPrices } = useFormatAmounts()
  const navigate = useNavigate()

  const formattedPrice = formatPrices(projectTokenPrices)

  const tokenAddress = projectConfig.addresses.find((address) => address.tokenID === projectConfig.key)
  if (!tokenAddress) return null

  const tokenExplorerURL = getExplorerTokenURL(tokenAddress.address)

  const handleOpenExplorer = () => {
    window.open(tokenExplorerURL, "_blank")
  }

  const handleAddTokenToWallet = () => window.domain.AddTokenToWalletEthereumUseCase.execute({ tokenID: PROJECT_KEY })

  const handleBuyClick = () => {
    navigate("/buy")
    onClose()
  }

  return (
    <Modal onClose={onClose} title={PROJECT_KEY}>
      <div className={styles.container}>
        <div className={styles.tutBox}>
          <div className={styles.price}>
            <span>
              <TokenIcon size="5xl" iconKey={PROJECT_KEY} />
            </span>
            <span>{formattedPrice}</span>
          </div>
          <div className={styles.addressBox}>
            <h3> {t("contractAdress")}</h3>
            {tokenAddress && <AddressBox account={tokenAddress.address} url={tokenExplorerURL} truncate={8} />}
          </div>
        </div>
        <div className={styles.buttonsBox}>
          <div className={styles.buttons}>
            <Button iconLeft={<DocIcon />} type={buttonTypes.OUTLINE} onClick={handleOpenExplorer} />
            {t("viewContract")}
          </div>
          {wallet.type === WalletState.TYPES.INJECTED && (
            <div className={styles.buttons}>
              <Button iconLeft={<WalletIcon />} type={buttonTypes.OUTLINE} onClick={handleAddTokenToWallet} />
              {t("walletAdd")}
            </div>
          )}
          <div className={styles.buttons}>
            <Button iconLeft={<BagIcon />} type={buttonTypes.OUTLINE} onClick={handleBuyClick} />
            {t("buy")}
          </div>
        </div>
      </div>
    </Modal>
  )
}
