import {
  Container,
  IncrementCard,
  TVLIcon,
  VolumeIcon,
  TransactionIcon,
  MoneyBagIcon,
  FeesIcon,
  IncomeIcon,
} from "@tutellus/tutellus-components"
import { useTranslation } from "react-i18next"
import { useFormatAmounts } from "../../../hooks/useFormatAmounts"
import { ProjectStats } from "../../../domain/project/Models/ProjectStats"

interface IncrementCardsListProps {
  projectStats: ProjectStats
}

export function IncrementCardsList({ projectStats }: IncrementCardsListProps) {
  const { t } = useTranslation("dashboard")
  const { formatAmount, formatCurrencyAmounts } = useFormatAmounts()

  return (
    <Container inner grid={3}>
      <IncrementCard
        title={t("tvl")}
        formattedCurrencyAmount={formatCurrencyAmounts(projectStats.tvl)}
        icon={<TVLIcon />}
      />
      <IncrementCard
        title={t("volume")}
        formattedCurrencyAmount={formatCurrencyAmounts(projectStats.volume)}
        icon={<VolumeIcon />}
      />
      <IncrementCard
        title={t("totalTx")}
        formattedCurrencyAmount={formatAmount(projectStats.tx)}
        icon={<TransactionIcon />}
      />
      <IncrementCard
        title={t("projectIncentives")}
        formattedCurrencyAmount={formatCurrencyAmounts(projectStats.inventives)}
        icon={<MoneyBagIcon />}
      />
      <IncrementCard
        title={t("generatedFees")}
        formattedCurrencyAmount={formatCurrencyAmounts(projectStats.fees)}
        icon={<FeesIcon />}
      />
      <IncrementCard
        title={t("protocolIncome")}
        formattedCurrencyAmount={formatCurrencyAmounts(projectStats.income)}
        icon={<IncomeIcon />}
      />
    </Container>
  )
}
