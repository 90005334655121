import { CurrenciesAllowedEnum } from "@tutellus/core-contracts-ifaces"
import { DECIMALS_CURRENCY_AMOUNT } from "../const/decimals"

const currencyLocaleMap = {
  [CurrenciesAllowedEnum.USD]: "en-US",
  [CurrenciesAllowedEnum.EUR]: "de-DE",
}

export const currencySymbolMap = {
  [CurrenciesAllowedEnum.USD]: "$",
  [CurrenciesAllowedEnum.EUR]: "€",
}

export const formatAmount = ({
  number,
  currencyID = CurrenciesAllowedEnum.USD,
  min = 0,
  max = 2,
}: {
  number: number
  currencyID?: CurrenciesAllowedEnum
  min?: number
  max?: number
}) => {
  const locale = currencyLocaleMap[currencyID]
  const minDisplayableValue = 1 / Math.pow(10, max)

  if (number > 0 && number < minDisplayableValue) {
    return `<${minDisplayableValue.toLocaleString(locale, {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    })}`
  }

  const factor = Math.pow(10, max)
  const truncated = Math.floor(number * factor) / factor

  return truncated.toLocaleString(locale, {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  })
}

export const formatCurrencyAmount = ({
  number,
  currencyID = CurrenciesAllowedEnum.USD,
  min = DECIMALS_CURRENCY_AMOUNT,
  max = DECIMALS_CURRENCY_AMOUNT,
}: {
  number: number
  currencyID: CurrenciesAllowedEnum
  min?: number
  max?: number
}) => {
  const symbol = currencySymbolMap[currencyID]
  const formattedAmount = formatAmount({ number, currencyID, min, max })

  if (CurrenciesAllowedEnum.USD === currencyID) {
    return `${symbol} ${formattedAmount}`
  } else if (CurrenciesAllowedEnum.EUR === currencyID) {
    return `${formattedAmount} ${symbol}`
  }

  return `${formattedAmount} ${symbol}`
}
