import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router"
import "./index.css"
import "./i18n.config"
import { DomainContext, EthereumContext, LayoutContext, TransactionsContext } from "./context"
import { Component as ErrorPage } from "./pages/Error"
import { initPWA } from "./js/pwa"
import { initSentry } from "./js/sentry"
import { dataRootLoader } from "./pages/DataRoot/loader"
import { Component as LayoutPage } from "./pages/Layout"
import { Component as LandingPage } from "./pages/Landing"
import { Component as DataRootPage } from "./pages/DataRoot"
import { Loading } from "./components/Loading"
import { pwaLoader } from "./pages/PWALanding/loader"
import { walletLoader } from "./pages/Wallet/loader"
import { swapLoader } from "./pages/Swap/loader"
import { poolDepositLoader } from "./pages/PoolDeposit/loader"

initSentry()

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="migration" id="migration" lazy={async () => import("./pages/Migration")} />
      <Route
        id="root"
        element={<DataRootPage />}
        loader={dataRootLoader}
        hydrateFallbackElement={<Loading />}
        errorElement={<ErrorPage />}
      >
        <Route path="connect" id="connect" lazy={async () => import("./pages/Connect")} />
        <Route path="pwa" lazy={async () => import("./pages/PWALanding")} loader={pwaLoader} />
        <Route element={<LayoutPage />}>
          <Route index element={<LandingPage />} />
          <Route path="dashboard" lazy={async () => import("./pages/Dashboard")} />
          <Route path="swap" lazy={async () => import("./pages/Swap")} loader={swapLoader} />
          <Route path="wallet" lazy={async () => import("./pages/Wallet")} loader={walletLoader} />
          <Route path="buy" lazy={async () => import("./pages/Buy")} />
          <Route path="liquidity" lazy={async () => import("./pages/Liquidity")} />
          <Route path="brand-kit" lazy={async () => import("./pages/BrandKit")} />
          <Route path="legal-disclaimer" lazy={async () => import("./pages/Disclaimer")} />
          <Route path="liquidity/:poolID" lazy={async () => import("./pages/PoolRoot")}>
            <Route path="deposit" lazy={async () => import("./pages/PoolDeposit")} loader={poolDepositLoader} />
            <Route path="withdraw" lazy={async () => import("./pages/PoolWithdraw")} />
          </Route>
          <Route path="vote" id="staking-root" lazy={async () => import("./pages/StakingRoot")}>
            <Route index element={<Navigate to="information" />} />
            <Route path="information" lazy={async () => import("./pages/StakingInfo")} />
            <Route path="voting" lazy={async () => import("./pages/StakingVotes")} />
            <Route path="generate" lazy={async () => import("./pages/StakingGenerate")} />
            <Route path="rewards" lazy={async () => import("./pages/StakingRewards")} />
          </Route>
          <Route path="explore" lazy={async () => import("./pages/ExploreRoot")}>
            <Route index element={<Navigate to="tokens" />} />
            <Route path="tokens" lazy={async () => import("./pages/ExploreTokens")} />
            <Route path="pools" lazy={async () => import("./pages/ExplorePools")} />
            <Route path="transactions" lazy={async () => import("./pages/ExploreTransactions")} />
          </Route>
          <Route path="explore/tokens/:tokenID" lazy={async () => import("./pages/ExploreTokenDetail")} />
          <Route path="explore/pools/:poolID" lazy={async () => import("./pages/ExplorePoolDetail")} />
          <Route path="nfts" lazy={async () => import("./pages/NFTsRoot")}>
            <Route index element={<Navigate to="info" />} />
            <Route path="info" lazy={async () => import("./pages/NFTsInfo")} />
            <Route path="merge" lazy={async () => import("./pages/NFTsMerge")} />
            <Route path="split" lazy={async () => import("./pages/NFTsSplitList")} />
            <Route path="split/:depositID" lazy={async () => import("./pages/NFTsSplit")} />
          </Route>
          <Route path="admin" id="admin-root" lazy={async () => import("./pages/AdminRoot")}>
            <Route index element={<Navigate to="close-epoch" replace />} />
            <Route path="close-epoch" lazy={async () => import("./pages/AdminCloseEpoch")} />
            <Route path="add-incentives" lazy={async () => import("./pages/AdminAddIncentives")} />
            <Route path="historical" lazy={async () => import("./pages/AdminHistorical")} />
          </Route>
        </Route>
      </Route>
    </>,
  ),
)

const App = () => {
  return (
    <React.StrictMode>
      <DomainContext>
        <EthereumContext>
          <LayoutContext>
            <TransactionsContext>
              <RouterProvider router={router} />
            </TransactionsContext>
          </LayoutContext>
        </EthereumContext>
      </DomainContext>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById("root")!).render(<App />)

initPWA()
