import { TokenTypeEnum } from "@tutellus/core-contracts-ifaces"
import { LoaderFunctionArgs, redirect } from "react-router"

const DEFAULT_INPUT_TOKEN = /USDT/i.source

export const swapLoader = async ({ request }: LoaderFunctionArgs) => {
  const { searchParams } = new URL(request.url)
  const inputToken = searchParams.get("inputToken")
  const outputToken = searchParams.get("outputToken")

  if (!inputToken || !outputToken) {
    const projectConfig = await window.domain.GetConfigProjectUseCase.execute()
    const tokensERC20 = projectConfig.tokens.filter((token) => token.type === TokenTypeEnum.ERC20)
    const defaultInputToken =
      inputToken ?? tokensERC20.find(({ id }) => id.includes(DEFAULT_INPUT_TOKEN))?.id ?? tokensERC20[0].id
    const defaultOutputToken = outputToken ?? import.meta.env.VITE_PROJECT_KEY
    searchParams.set("inputToken", defaultInputToken)
    searchParams.set("outputToken", defaultOutputToken)

    return redirect(`?${searchParams.toString()}`)
  }

  if (inputToken === outputToken) {
    if (inputToken === import.meta.env.VITE_PROJECT_KEY) {
      searchParams.delete("outputToken")
      searchParams.delete("inputToken")

      return redirect(`?${searchParams.toString()}`)
    }
    const defaultInputToken = import.meta.env.VITE_PROJECT_KEY
    searchParams.set("inputToken", defaultInputToken)

    return redirect(`?${searchParams.toString()}`)
  }

  return null
}
