import { LoaderFunctionArgs, redirect } from "react-router"
import { WalletState } from "../../domain/ethereum/Models/WalletState"

export const walletLoader = async ({ request }: LoaderFunctionArgs) => {
  const walletState = await window.domain.GetWalletStateEthereumUseCase.execute()

  if (walletState.status !== WalletState.STATUS.CONNECTED) {
    const url = new URL(request.url)
    const currentPath = url.pathname

    if (currentPath) {
      return redirect(`/connect?redirectTo=${encodeURIComponent(currentPath)}`)
    } else {
      return redirect("/connect")
    }
  }

  return null
}
