import { PropsWithChildren, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { AlertIcon, Button, CheckIcon, CopyIcon, Modal, type ModalProps } from "@tutellus/tutellus-components"
import { useEthereum } from "../../../../context"
import { copyToClipboard } from "../../../../js/string"
import cx from "classnames"
import styles from "./index.module.css"

export type ErrorModalProps = PropsWithChildren &
  Omit<ModalProps, "title" | "children"> & {
    error?: Error
    autoHideCopyMessage?: boolean
    copyMessageDuration?: number
  }

export const ErrorModal = ({
  children,
  error,
  width = "s",
  autoHideCopyMessage = true,
  copyMessageDuration = 3000,
  ...modalProps
}: ErrorModalProps) => {
  const { t } = useTranslation("common")
  const [isCopied, setIsCopied] = useState(false)
  const { wallet } = useEthereum()

  // Reset copy state after duration
  useEffect(() => {
    if (isCopied && autoHideCopyMessage) {
      const timer = setTimeout(() => setIsCopied(false), copyMessageDuration)
      return () => clearTimeout(timer)
    }
  }, [isCopied, autoHideCopyMessage, copyMessageDuration])

  const getErrorMessage = (error: Error) => `${t("address")}: ${wallet.account}\n\n${t("error")}: ${error.message}`

  const handleCopy = (text: string) => {
    copyToClipboard(text)
      .then(() => setIsCopied(true))
      .catch((err) => console.error("Error copying: ", err))
  }

  const copyLiteral = isCopied ? t("copied") : t("copy")

  return (
    <Modal {...modalProps} title={t("error")} width={width}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.iconWrapper}>
            <AlertIcon />
          </span>
          <h3 className={styles.title}>{t("error")}</h3>
        </div>

        {error && (
          <div className={styles.errorSection}>
            <div className={styles.errorContent}>
              <p className={styles.errorText}>{getErrorMessage(error)}</p>
            </div>
            <button
              className={styles.copyButton}
              onClick={() => handleCopy(getErrorMessage(error))}
              aria-label={t("copy")}
            >
              <span className={cx(styles.copyText, isCopied && styles.copyTextSuccess)}>{copyLiteral}</span>
              <span className={cx(styles.copyIcon, isCopied && styles.copyIconSuccess)}>
                {isCopied ? <CheckIcon /> : <CopyIcon />}
              </span>
            </button>
          </div>
        )}

        {children && <div className={styles.childrenContainer}>{children}</div>}

        <div className={styles.footer}>
          <a
            href="https://discord.com/channels/762592062822023179/1337428810702000231"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.supportLink}
          >
            <Button isFull onClick={modalProps.onClose}>
              {t("sendToSupport")}
            </Button>
          </a>
        </div>
      </div>
    </Modal>
  )
}
