import { ProjectConfig } from "../../domain/project/Models/ProjectConfig"
import { ProjectStats } from "../../domain/project/Models/ProjectStats"
import { TokenPriceList } from "../../domain/token/Models/PriceList"

const PROJECT_KEY = import.meta.env.VITE_PROJECT_KEY

export type RootDataLoader = {
  projectConfig: ProjectConfig
  projectStats: ProjectStats
  prices: TokenPriceList
  projectTokenPrices: TokenPriceList
}

export const dataRootLoader = async () => {
  if (!window.publicClient || !window.blockNumber) {
    await window.domain.InitPublicClientEthereumUseCase.execute()
  }

  const [projectConfig, projectStats, prices] = await Promise.all([
    window.domain.GetConfigProjectUseCase.execute(),
    window.domain.GetStatsProjectUseCase.execute(),
    window.domain.GetPriceListTokenUseCase.execute(),
  ])

  const projectTokenPrices = prices.getTokenPrices(PROJECT_KEY)

  return {
    projectConfig,
    projectStats,
    prices,
    projectTokenPrices,
  } satisfies RootDataLoader
}
