import { Container, MediumIcon, TelegramIcon, XIcon } from "@tutellus/tutellus-components"
import styles from "./index.module.css"
import { Link } from "react-router"
import { useTranslation } from "react-i18next"
import { GITBOOK_URL, MEDIUM_URL, TELEGRAM_URL, X_URL } from "../../../js/const/urls"

export const Footer = () => {
  const { t } = useTranslation("footer")

  return (
    <Container>
      <div className={styles.footer}>
        <div className={styles.socialMediaIcons}>
          <Link className={styles.link} title="Twitter" to={X_URL} target="_blank" rel="noreferrer">
            <XIcon />{" "}
          </Link>
          <Link className={styles.link} to={TELEGRAM_URL} title="Telegram" target="_blank" rel="noreferrer">
            <TelegramIcon />{" "}
          </Link>
          <Link className={styles.link} to={MEDIUM_URL} title="Medium" target="_blank" rel="noreferrer">
            <MediumIcon />{" "}
          </Link>
        </div>
        <nav className={styles.nav}>
          <Link className={styles.linkText} to={GITBOOK_URL} target="_blank" rel="noreferrer">
            {t("documentation")}
          </Link>
          <Link className={styles.linkText} to="/brand-kit">
            {t("brandKit")}
          </Link>
          <Link className={styles.linkText} to="/legal-disclaimer">
            {t("legalDisclaimer")}
          </Link>
        </nav>
        <p className={styles.powered}>GT3 2025. Powered by GT3 DAO LLC</p>
      </div>
    </Container>
  )
}
