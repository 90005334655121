import { ReactNode } from "react"
import styles from "./index.module.css"
import cx from "classnames"

const marginBottomSizes = {
  s: "s",
  m: "m",
  l: "l",
  xl: "xl",
  xxl: "xxl",
} as const

type MarginBottomSize = keyof typeof marginBottomSizes

interface ContainerProps {
  children: ReactNode
  grid?: number
  justifyCenter?: boolean
  mb?: MarginBottomSize
  className?: string
}

export const Container = ({ children, grid, justifyCenter, mb, className }: ContainerProps) => {
  const gridStyle = grid ? { gridTemplateColumns: `repeat(${grid}, 1fr)` } : {}
  const mbClass = mb ? styles[`mb-${mb}`] : ""
  const containerClassNames = cx(
    styles.container,
    grid ? styles.grid : "",
    justifyCenter ? styles.justifyCenter : "",
    mbClass,
    className,
  )

  return (
    <div className={containerClassNames} style={gridStyle}>
      {children}
    </div>
  )
}

export { marginBottomSizes }
