import { polygon } from "viem/chains";
export const POLYGON = {
    id: polygon.id,
    name: polygon.name,
    logo: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg",
    explorer: "https://polygonscan.com/",
    isTestnet: false,
    pollingInterval: 15_000,
    viemConfig: polygon,
};
