import buyEN from "./en/buy.json"
import buyES from "./es/buy.json"
import brandEN from "./en/brand.json"
import brandES from "./es/brand.json"
import landingEN from "./en/landing.json"
import landingES from "./es/landing.json"
import commonEN from "./en/common.json"
import commonES from "./es/common.json"
import dashboardEN from "./en/dashboard.json"
import dashboardES from "./es/dashboard.json"
import disclaimerEN from "./en/disclaimer.json"
import disclaimerES from "./es/disclaimer.json"
import errorEN from "./en/error.json"
import errorES from "./es/error.json"
import exploreEN from "./en/explore.json"
import exploreES from "./es/explore.json"
import glossaryEN from "./en/glossary.json"
import glossaryES from "./es/glossary.json"
import liquidityEN from "./en/liquidity.json"
import liquidityES from "./es/liquidity.json"
import loginEN from "./en/login.json"
import loginES from "./es/login.json"
import migrationEN from "./en/migration.json"
import migrationES from "./es/migration.json"
import nftsEN from "./en/nfts.json"
import nftsES from "./es/nfts.json"
import poolEN from "./en/pool.json"
import poolES from "./es/pool.json"
import settingsEN from "./en/settings.json"
import settingsES from "./es/settings.json"
import stakingEN from "./en/staking.json"
import stakingES from "./es/staking.json"
import swapEN from "./en/swap.json"
import swapES from "./es/swap.json"
import pwaEN from "./en/pwa.json"
import pwaES from "./es/pwa.json"
import urlsEN from "./en/urls.json"
import urlsES from "./es/urls.json"
import syntheticsEN from "./en/synthetics.json"
import syntheticsES from "./es/synthetics.json"
import walletEN from "./en/wallet.json"
import walletES from "./es/wallet.json"
import footerEN from "./en/footer.json"
import footerES from "./es/footer.json"

export const es = {
  buy: buyES,
  brand: brandES,
  landing: landingES,
  common: commonES,
  dashboard: dashboardES,
  disclaimer: disclaimerES,
  error: errorES,
  explore: exploreES,
  glossary: glossaryES,
  liquidity: liquidityES,
  login: loginES,
  migration: migrationES,
  nfts: nftsES,
  pool: poolES,
  settings: settingsES,
  staking: stakingES,
  swap: swapES,
  synthetics: syntheticsES,
  wallet: walletES,
  pwa: pwaES,
  urls: urlsES,
  footer: footerES,
}

export const en = {
  buy: buyEN,
  brand: brandEN,
  landing: landingEN,
  common: commonEN,
  dashboard: dashboardEN,
  disclaimer: disclaimerEN,
  error: errorEN,
  explore: exploreEN,
  glossary: glossaryEN,
  liquidity: liquidityEN,
  login: loginEN,
  migration: migrationEN,
  nfts: nftsEN,
  pool: poolEN,
  settings: settingsEN,
  staking: stakingEN,
  swap: swapEN,
  synthetics: syntheticsEN,
  wallet: walletEN,
  pwa: pwaEN,
  urls: urlsEN,
  footer: footerEN,
}
