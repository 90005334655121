import cx from "classnames"
import React, { useEffect, useState } from "react"
import { useRouteLoaderData, useNavigate, Link, NavLink } from "react-router"
import { useTranslation } from "react-i18next"
import {
  Button,
  buttonTypes,
  buttonSizes,
  TopNavMenu,
  TokenRewards,
  TokenIcon,
  SettingsIcon,
  Spinner,
  buttonColors,
  WalletIcon,
} from "@tutellus/tutellus-components"
import { Container } from "../../ui/container"
import { useEthereum } from "../../../context"
import { useLayout, ThemeMode } from "../../../context"
import { useFormatAmounts } from "../../../hooks/useFormatAmounts"
import { RootDataLoader } from "../../../pages/DataRoot/loader"
import { DATA_TEST_IDS } from "../../../../e2e/config/ids"
import { Transaction } from "../../../domain/ethereum/Models/Transaction"
import { truncateAddress } from "../../../js/string"
import {
  GT3_DESKTOP_LOGO_DARK,
  GT3_DESKTOP_LOGO_LIGHT,
  GT3_MOBILE_LOGO_DARK,
  GT3_MOBILE_LOGO_LIGHT,
} from "../../../js/const/logos"
import styles from "./index.module.css"

const PROJECT_KEY = import.meta.env.VITE_PROJECT_KEY

interface TopbarProps {
  menuItems: {
    label: string
    tooltip: string
    url?: string
    icon: React.ElementType
    onClick?: () => void
  }[]
  currentRoute: string
  totalRewards: number
  formattedTotalRewards: string
  onShowTokenModal: () => void
  onShowWalletModal: () => void
  onShowRewardsModal: () => void
  pendingTransactions: Transaction[]
}

export const Topbar = ({
  menuItems,
  currentRoute,
  totalRewards,
  formattedTotalRewards,
  onShowTokenModal,
  onShowWalletModal,
  onShowRewardsModal,
  pendingTransactions,
}: TopbarProps) => {
  const { projectTokenPrices } = useRouteLoaderData("root") as RootDataLoader
  const navigate = useNavigate()
  const { wallet, isWalletConnected } = useEthereum()
  const { themeMode } = useLayout()
  const { formatPrices } = useFormatAmounts()
  const { t } = useTranslation("common")
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleRouteChange = (url: string) => navigate(url)

  const formattedPrice = formatPrices(projectTokenPrices)
  const hasPendingTransactions = pendingTransactions.length > 0
  const walletFormatted = wallet.account ? truncateAddress(wallet.account) : ""
  const iconWallet = wallet.connector?.icon

  const logoDesktop = themeMode === ThemeMode.LIGHT ? GT3_DESKTOP_LOGO_DARK : GT3_DESKTOP_LOGO_LIGHT
  const logoMobile = themeMode === ThemeMode.LIGHT ? GT3_MOBILE_LOGO_DARK : GT3_MOBILE_LOGO_LIGHT

  return (
    <div className={cx(styles.topbar, { [styles.scrolled]: isScrolled })} data-testid={DATA_TEST_IDS.TOPBAR_CONTAINER}>
      <Container>
        <div className={styles.topbarContent}>
          <NavLink to="/" className={styles.logo}>
            <img className={styles.logoDesktop} src={logoDesktop} alt="GT3 Logo" />
            <img className={styles.logoMobile} src={logoMobile} alt="GT3 Logo" />
          </NavLink>
          <div className={styles.menu}>
            <TopNavMenu transparent menu={menuItems} currentRoute={currentRoute} onRouteChange={handleRouteChange} />
          </div>

          <div className={styles.rightContent}>
            {isWalletConnected && (
              <Button
                onClick={onShowRewardsModal}
                hideTextOnMobile
                isGradientBorder
                isDisabled={totalRewards === 0}
                size={buttonSizes.SMALL}
                iconLeft={<TokenRewards />}
                data-testid={DATA_TEST_IDS.REWARDS_BUTTON}
              >
                {formattedTotalRewards}
              </Button>
            )}
            <Button
              hideTextOnMobile
              type={buttonTypes.OUTLINE}
              size={buttonSizes.SMALL}
              iconLeft={<TokenIcon size="full" iconKey={PROJECT_KEY} />}
              color={buttonColors.SURFACE_INVERSE}
              onClick={onShowTokenModal}
            >
              {formattedPrice}
            </Button>

            {isWalletConnected && (
              <Button
                onClick={onShowWalletModal}
                type={buttonTypes.OUTLINE}
                size={buttonSizes.SMALL}
                color={buttonColors.SURFACE_INVERSE}
                iconLeft={hasPendingTransactions ? <Spinner size="m" /> : <img src={iconWallet} alt="Wallet icon" />}
                hideTextOnMobile
              >
                {walletFormatted}
              </Button>
            )}
            {!isWalletConnected && (
              <Link to="/connect">
                <Button size={buttonSizes.SMALL} color={buttonColors.ACCENT} iconLeft={<WalletIcon />} hideTextOnMobile>
                  {t("connect")}
                </Button>
              </Link>
            )}
            <div className={styles.settings}>
              <SettingsIcon />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
