import { Transaction, TransactionType } from "./Transaction"

export type TransactionListSerialized = ReturnType<TransactionList["serialize"]>

export class TransactionList {
  constructor(private readonly _items: Transaction[]) {}

  static create({ items }: { items: Transaction[] }) {
    return new TransactionList(items)
  }

  static empty() {
    return new TransactionList([])
  }

  isPendingByType(type: TransactionType) {
    return this.pending.some((tx) => tx.type === type)
  }

  isSuccesByHash(hash: string) {
    return this._items.some((tx) => tx.hash === hash && tx.status === Transaction.STATUS.SUCCESS)
  }

  get items() { return this._items } // prettier-ignore
  get pending() { return this._items.filter(({ status }) => status === Transaction.STATUS.PENDING) } // prettier-ignore
  get isEmpty() { return this._items.length === 0 } // prettier-ignore

  get isPendingAddIncentive() { return this.isPendingByType(TransactionType.ADD_INCENTIVE) } // prettier-ignore
  get isPendingAddLiquidity() { return this.isPendingByType(TransactionType.ADD_LIQUIDITY) } // prettier-ignore
  get isPendingApprove() { return this.isPendingByType(TransactionType.APPROVE) } // prettier-ignore
  get isPendingClaim() { return this.isPendingByType(TransactionType.CLAIM) } // prettier-ignore
  get isPendingClaimPool() { return this.isPendingByType(TransactionType.CLAIM_POOL) } // prettier-ignore
  get isPendingClaimReward() { return this.isPendingByType(TransactionType.CLAIM_REWARD) } // prettier-ignore
  get isPendingClaimSynthetic() { return this.isPendingByType(TransactionType.CLAIM_SYNTHETIC) } // prettier-ignore
  get isPendingCloseEpoch() { return this.isPendingByType(TransactionType.CLOSE_EPOCH) } // prettier-ignore
  get isPendingDeposit() { return this.isPendingByType(TransactionType.DEPOSIT) } // prettier-ignore
  get isPendingDepositStake() { return this.isPendingByType(TransactionType.DEPOSIT_STAKE) } // prettier-ignore
  get isPendingIncreaseStake() { return this.isPendingByType(TransactionType.INCREASE_STAKE) } // prettier-ignore
  get isPendingMergeNFT() { return this.isPendingByType(TransactionType.MERGE_NFT) } // prettier-ignore
  get isPendingMigrate() { return this.isPendingByType(TransactionType.MIGRATE) } // prettier-ignore
  get isPendingRebase() { return this.isPendingByType(TransactionType.REBASE) } // prettier-ignore
  get isPendingRebuildStake() { return this.isPendingByType(TransactionType.REBUILD_STAKE) } // prettier-ignore
  get isPendingSplitNFT() { return this.isPendingByType(TransactionType.SPLIT_NFT) } // prettier-ignore
  get isPendingStakeLiquidity() { return this.isPendingByType(TransactionType.STAKE_LIQUIDITY) } // prettier-ignore
  get isPendingSwap() { return this.isPendingByType(TransactionType.SWAP) } // prettier-ignore
  get isPendingTransfer() { return this.isPendingByType(TransactionType.TRANSFER) } // prettier-ignore
  get isPendingUnstakeLiquidity() { return this.isPendingByType(TransactionType.UNSTAKE_LIQUIDITY) } // prettier-ignore
  get isPendingVote() { return this.isPendingByType(TransactionType.VOTE) } // prettier-ignore
  get isPendingWithdraw() { return this.isPendingByType(TransactionType.WITHDRAW) } // prettier-ignore
  get isPendingWithdrawLiquidity() { return this.isPendingByType(TransactionType.WITHDRAW_LIQUIDITY) } // prettier-ignore
  get isPendingWithdrawStake() { return this.isPendingByType(TransactionType.WITHDRAW_STAKE) } // prettier-ignore

  serialize() {
    return {
      items: this._items.map((transaction) => transaction.serialize()),
      pending: this.pending.map((transaction) => transaction.serialize()),
    }
  }
}
