import { useCallback } from "react"

interface XShareOptions {
  /** Default text to share */
  defaultText?: string
  /** Comma-separated hashtags (without the # symbol) */
  hashtags?: string
  /** Username to attribute the post to (without the @ symbol) */
  via?: string
  /** URL to include in the post */
  url?: string
}

interface CustomShareOptions {
  /** Comma-separated hashtags (without the # symbol) */
  hashtags?: string
  /** Username to attribute the post to (without the @ symbol) */
  via?: string
  /** URL to include in the post */
  url?: string
}

interface UseShareReturn {
  /** Shares content on X by opening a new window */
  shareOnX: (text?: string, customOptions?: CustomShareOptions) => void
  /** Generates a URL for sharing on X (useful for links) */
  getXShareUrl: (text?: string, customOptions?: CustomShareOptions) => string
}

/**
 * Custom hook for sharing content on X (formerly Twitter)
 * @param options - Configuration options for sharing on X
 * @returns Object with functions for sharing on X
 */
export const useShare = (options: XShareOptions = {}): UseShareReturn => {
  const { defaultText = "", hashtags = "", via = "", url = "" } = options

  /**
   * Shares on X with the provided text and options
   * @param text - Custom text to share (optional)
   * @param customOptions - Custom options for this specific share
   */
  const shareOnX = useCallback(
    (text: string = defaultText, customOptions: CustomShareOptions = {}) => {
      // Combine default options with custom options
      const finalOptions = {
        hashtags: customOptions.hashtags || hashtags,
        via: customOptions.via || via,
        url: customOptions.url || url,
      }

      // Create base URL
      const xIntentUrl = "https://x.com/intent/tweet?"

      // Add parameters to the URL
      const params = new URLSearchParams()

      if (text) params.append("text", text)
      if (finalOptions.hashtags) params.append("hashtags", finalOptions.hashtags)
      if (finalOptions.via) params.append("via", finalOptions.via)
      if (finalOptions.url) params.append("url", finalOptions.url)

      // Open X window with the parameters
      window.open(xIntentUrl + params.toString(), "_blank")
    },
    [defaultText, hashtags, via, url],
  )

  /**
   * Generates a URL for sharing on X without opening a new window
   * @param text - Custom text to share (optional)
   * @param customOptions - Custom options
   * @returns Complete URL for sharing on X
   */
  const getXShareUrl = useCallback(
    (text: string = defaultText, customOptions: CustomShareOptions = {}): string => {
      const finalOptions = {
        hashtags: customOptions.hashtags || hashtags,
        via: customOptions.via || via,
        url: customOptions.url || url,
      }

      const params = new URLSearchParams()

      if (text) params.append("text", text)
      if (finalOptions.hashtags) params.append("hashtags", finalOptions.hashtags)
      if (finalOptions.via) params.append("via", finalOptions.via)
      if (finalOptions.url) params.append("url", finalOptions.url)

      return `https://x.com/intent/tweet?${params.toString()}`
    },
    [defaultText, hashtags, via, url],
  )

  return {
    shareOnX,
    getXShareUrl,
  }
}
