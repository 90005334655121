import { useTranslation } from "react-i18next"
import {
  Button,
  buttonColors,
  buttonTypes,
  BuyIcon,
  Container,
  Heading,
  StatsIcon,
  Swap,
  SwapIcon,
  VoteIcon,
  TokenIcon,
  WalletIcon,
  SwapTokenInfo,
} from "@tutellus/tutellus-components"
import { Link, NavLink, useNavigate, useRouteLoaderData } from "react-router"
import styles from "./index.module.css"
import { ThemeMode, useLayout } from "../../context"
import { RootDataLoader } from "../DataRoot/loader"
import { shuffleArray } from "../../js/array"
import { IncrementCardsList } from "../../components/modules/IncrementsCardsList"

import { TokenTypeEnum } from "@tutellus/core-contracts-ifaces"
import { MEDIUM_URL, TELEGRAM_URL } from "../../js/const/urls"

const DEFAULT_INPUT_TOKEN = /USDT/i.source

const dummyInputTokenAmount = { tokenID: "USDT", amount: "0", bigint: 100n, currencyAmount: "$0" }
const dummyOutputTokenAmount = { tokenID: "GT3", amount: "0", bigint: 1n, currencyAmount: "$0" }
const dummyInputTokenBalance = { tokenID: "USDT", amount: "0", bigint: 1000n, formatted: "0" }
const dummyOutputTokenBalance = { tokenID: "GT3", amount: "0", bigint: 50n, formatted: "0" }
interface GuideCardProps {
  imageSrc: string
  title: string
  description: string
  link: string
  buttonText: string
  buttonColor?: string
}
const GuideCard: React.FC<GuideCardProps> = ({ imageSrc, title, description, link, buttonText }) => (
  <div className={styles.cardGuide}>
    <div className={styles.cardGuideImg}>
      <img src={imageSrc} alt={title} />
    </div>
    <div className={styles.cardGuideBody}>
      <Heading as="h3">{title}</Heading>
      <p>{description}</p>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Button type={buttonTypes.OUTLINE}>{buttonText}</Button>
      </a>
    </div>
  </div>
)

// Items Carousel Hero Card
interface CarouselCardProps {
  to: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  label: string
}
const CarouselCard: React.FC<CarouselCardProps> = ({ to, Icon, label }) => (
  <NavLink className={styles.carouselCard} to={to}>
    <Icon />
    <p>{label}</p>
  </NavLink>
)

// Tokens
const tokenIconKeys = ["GT3", "RNT", "FIT", "ZHT", "WPOL", "USDC", "USDT"]
const TokenIconList: React.FC = () => (
  <div className={styles.tokens}>
    {tokenIconKeys.map((iconKey, index) => (
      <span key={index} className={styles.tokenAnimation}>
        <TokenIcon iconKey={iconKey} />
      </span>
    ))}
  </div>
)

// Testimonial Card
interface TestimonialCardProps {
  name: string
  image: string
  quote: string
}
const TestimonialCard: React.FC<TestimonialCardProps> = ({ name, image, quote }) => (
  <div className={styles.userTestimonial}>
    <img className={styles.userTestimonialImg} src={image} alt={name} />
    <Heading as="h3" color="on-accent">
      {name}
    </Heading>
    <p>{quote}</p>
  </div>
)

// ─── SECTIONS ───────────────────────────────────

// Hero
function HeroSection() {
  const { t } = useTranslation(["landing", "swap"])
  const navigate = useNavigate()
  const { projectConfig } = useRouteLoaderData("root") as RootDataLoader

  const fakeGetAmountAllowance = () => true
  const fakeOnApprove = async () => {}
  const fakeOnSwap = async () => navigate("/swap")

  const fakeOnInputTokenAmountChange = async () => {}
  const fakeOnOutputTokenAmountChange = async () => {}
  const fakeOnInputTokenChange = async () => {}
  const fakeOnOutputTokenChange = async () => {}
  const fakeOnSwitchTokens = async () => {}
  const fakeOnDeadlineChange = async () => {}
  const fakeOnSlippageChange = async () => {}

  const availableTokens = projectConfig.tokens
    .filter((token) => token.type === TokenTypeEnum.ERC20)
    .map((tokensConfig) => {
      const address = projectConfig.addresses.find(({ tokenID }) => tokenID === tokensConfig.id)!.address
      const swapTokenInfo: SwapTokenInfo = {
        tokenID: tokensConfig.id,
        symbol: tokensConfig.symbol,
        name: tokensConfig.name,
        decimals: tokensConfig.decimals,
        icon: <TokenIcon iconKey={tokensConfig.id} />,
        address,
      }
      return swapTokenInfo
    })

  const tokensERC20 = projectConfig.tokens.filter((token) => token.type === TokenTypeEnum.ERC20)
  const defaultInputToken = tokensERC20.find(({ id }) => id.includes(DEFAULT_INPUT_TOKEN))?.id ?? tokensERC20[0].id
  const defaultOutputToken = import.meta.env.VITE_PROJECT_KEY

  return (
    <section className={styles.hero}>
      <h1 className={styles.heroH1}>{t("hero.title")}</h1>
      <p>{t("hero.description")}</p>
      <div className={styles.swap}>
        <NavLink to="/swap">
          <Swap
            availableTokens={availableTokens}
            deadline={2}
            slippage={0.5}
            getAmountAllowance={fakeGetAmountAllowance}
            inputTokenAmount={dummyInputTokenAmount}
            inputTokenBalance={dummyInputTokenBalance}
            inputTokenID={defaultInputToken}
            isApproveLoading={false}
            isSwapLoading={false}
            isInputLoading={false}
            isOutputLoading={false}
            literals={t("swap:component", { returnObjects: true })}
            onApprove={fakeOnApprove}
            onSwap={fakeOnSwap}
            onInputTokenAmountChange={fakeOnInputTokenAmountChange}
            onOutputTokenAmountChange={fakeOnOutputTokenAmountChange}
            onInputTokenChange={fakeOnInputTokenChange}
            onOutputTokenChange={fakeOnOutputTokenChange}
            onSwitchTokens={fakeOnSwitchTokens}
            onDeadlineChange={fakeOnDeadlineChange}
            onSlippageChange={fakeOnSlippageChange}
            outputTokenAmount={dummyOutputTokenAmount}
            outputTokenBalance={dummyOutputTokenBalance}
            outputTokenID={defaultOutputToken}
            priceImpact={0}
            path={[]}
          />
        </NavLink>
      </div>
    </section>
  )
}

// Mobile Experience Section
function MobileExperienceSection() {
  const { t } = useTranslation(["landing", "urls"])

  const { themeMode } = useLayout()
  const buttonOnImage = themeMode === ThemeMode.LIGHT ? buttonColors.SURFACE_INVERSE : buttonColors.ON_SURFACE_INVERSE

  return (
    <section className={styles.defi}>
      <h2 className={styles.heroH2}>{t("mobileExperience.title")}</h2>
      <p className={styles.heroDescription}>
        {t("mobileExperience.description")}
        <br />
        {t("mobileExperience.description2")}
      </p>
      <div className={styles.logosMobileContainer}>
        <p>{t("mobileExperience.compatible")}</p>
        <div className={styles.logosMobile}>
          <NavLink className={styles.logoBgIos} to="/connect">
            <img
              src="https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d956d8040f20fa220d7122_ios.png"
              alt="iOS"
            />
          </NavLink>
          <NavLink className={styles.logoBg} to="/connect">
            <img
              src="https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d956aac101d8b95852e7d5_zoom-android-new-3d-logo-wordmark.webp"
              alt="Android"
            />
          </NavLink>
        </div>
      </div>
      <div className={styles.heroCard}>
        <div className={styles.heroLeft}>
          <h2 className={styles.heroHeadingCard}>{t("characteristics.title")}</h2>
          <p>{t("characteristics.description")}</p>
        </div>
        <div className={styles.heroRight}>
          <CarouselCard to="/buy" Icon={BuyIcon} label={t("carousel.buy")} />
          <CarouselCard to="/swap" Icon={SwapIcon} label={t("carousel.swap")} />
          <CarouselCard to="/liquidity" Icon={BuyIcon} label={t("carousel.liquidity")} />
          <CarouselCard to="/vote" Icon={VoteIcon} label={t("carousel.vote")} />
          <CarouselCard to="/explore" Icon={StatsIcon} label={t("carousel.explore")} />
          <CarouselCard to="/wallet" Icon={WalletIcon} label={t("carousel.wallet")} />
        </div>
      </div>
      <div className={styles.gridCards}>
        <div className={styles.appMobile}>
          <h3 className={styles.headingCard}>{t("app.title")}</h3>
          <p>{t("app.description")}</p>
          <a href={t("urls:guides.home")} target="_blank" rel="noopener noreferrer">
            <Button color={buttonOnImage} type={buttonTypes.OUTLINE}>
              {t("app.button")}
            </Button>
          </a>
          <img className={styles.appMobileImage} src={t("app.image")} alt="Mobile App" />
        </div>
        <div className={styles.biometrics}>
          <img className={styles.biometricsImage} src={t("biometrics.image")} alt="Biometrics" />
          <h3 className={styles.headingCard}>{t("biometrics.title")}</h3>
          <p>{t("biometrics.description")}</p>
          <Link to="/connect">
            <Button color={buttonOnImage} type={buttonTypes.OUTLINE}>
              {t("biometrics.button")}
            </Button>
          </Link>
        </div>
        <div className={styles.getTokens}>
          <div className={styles.getTokensInfo}>
            <h3 className={styles.headingCard}>{t("getTokens.title")}</h3>
            <p>{t("getTokens.description")}</p>
            <TokenIconList />
            <Link to="/buy">
              <Button color={buttonOnImage} type={buttonTypes.OUTLINE}>
                {t("getTokens.button")}
              </Button>
            </Link>
          </div>
          <img className={styles.buyImage} src={t("getTokens.image")} alt="Buy Tokens" />
        </div>
      </div>
    </section>
  )
}

// DeFi Actions
function DeFiActionsSection() {
  const { t } = useTranslation("landing")
  const { themeMode } = useLayout()
  const buttonOnImage = themeMode === ThemeMode.LIGHT ? buttonColors.SURFACE_INVERSE : buttonColors.ON_SURFACE_INVERSE

  return (
    <section className={styles.defi}>
      <h2 className={styles.heroH2}>{t("defi.title")}</h2>
      <p className={styles.heroDescription}>{t("defi.description")}</p>
      <div className={styles.gridCards}>
        <div className={styles.liquidityCard}>
          <h3 className={styles.headingCard}>{t("defi.liquidity.title")}</h3>
          <p>{t("defi.liquidity.description")}</p>
          <Link to="/liquidity">
            <Button type={buttonTypes.OUTLINE} color={buttonOnImage}>
              {t("defi.liquidity.button")}
            </Button>
          </Link>
        </div>
        <div className={styles.voteCard}>
          <h3 className={styles.headingCard}>{t("defi.vote.title")}</h3>
          <p>{t("defi.vote.description")}</p>
          <Link to="/vote">
            <Button type={buttonTypes.OUTLINE} color={buttonOnImage}>
              {t("defi.vote.button")}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  )
}

// Statistics
function StatisticsSection() {
  const { t } = useTranslation("landing")
  const { projectStats } = useRouteLoaderData("root") as RootDataLoader

  return (
    <section className={styles.characteristics}>
      <h2 className={styles.heroH2}>{t("stats.title")}</h2>
      <p className={styles.heroDescription}>{t("stats.description")}</p>
      <IncrementCardsList projectStats={projectStats} />
    </section>
  )
}

// Characteristics
function FeaturesSection() {
  const { t } = useTranslation("landing")
  return (
    <section className={styles.characteristics}>
      <h2 className={styles.heroH2}>{t("characteristicsSection.title")}</h2>
      <p className={styles.heroDescription}>{t("characteristicsSection.description")}</p>
      <Container grid={3} inner>
        <div className={styles.cardPrimary}>
          <span className={styles.handsIcon} />
          <p>{t("features", { returnObjects: true })[0]}</p>
        </div>
        <div className={styles.cardPrimary}>
          <span className={styles.incentivesIcon} />
          <p>{t("features", { returnObjects: true })[1]}</p>
        </div>
        <div className={styles.cardPrimary}>
          <span className={styles.projectsIcon} />
          <p>{t("features", { returnObjects: true })[2]}</p>
        </div>
        <div className={styles.cardPrimary}>
          <span className={styles.decentralizedIcon} />
          <p>{t("features", { returnObjects: true })[3]}</p>
        </div>
        <div className={styles.cardPrimary}>
          <span className={styles.daoIcon} />
          <p>{t("features", { returnObjects: true })[4]}</p>
        </div>
        <div className={styles.cardPrimary}>
          <span className={styles.cycleIcon} />
          <p>{t("features", { returnObjects: true })[5]}</p>
        </div>
      </Container>
    </section>
  )
}

// Testimonials
function TestimonialsSection() {
  const { t } = useTranslation("landing")

  const data = [
    {
      key: "vicente",
      name: t("testimonials.vicente.name"),
      image: "https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d67d3c90b4d2b580d9bbe8_vicente.avif",
      quote: t("testimonials.vicente.quote"),
    },
    {
      key: "jesus",
      name: t("testimonials.jesus.name"),
      image: "https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d67d3c4eb472a083bcae08_jesus_respeto.avif",
      quote: t("testimonials.jesus.quote"),
    },
    {
      key: "alvaro",
      name: t("testimonials.alvaro.name"),
      image: "https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d67d3c4eb472a083bcadeb_alvaro.avif",
      quote: t("testimonials.alvaro.quote"),
    },
    {
      key: "koldo",
      name: t("testimonials.koldo.name"),
      image: "https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d67d3c76d28f60e18b460e_koldo.avif",
      quote: t("testimonials.koldo.quote"),
    },
    {
      key: "stacy",
      name: t("testimonials.stacy.name"),
      image: "https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d67d3cbf3b756fc972752b_stacy.avif",
      quote: t("testimonials.stacy.quote"),
    },
    {
      key: "maria",
      name: t("testimonials.maria.name"),
      image: "https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d9acfc2142664f179edbe7_maria.avif",
      quote: t("testimonials.maria.quote"),
    },
  ]

  const testimonials = shuffleArray(data)

  return (
    <section className={styles.testimonials}>
      <h2 className={styles.heroH2}>{t("testimonials.title")}</h2>
      <p className={styles.heroDescription}>{t("testimonials.description")}</p>
      <Container grid={3} inner>
        {testimonials.map((item) => (
          <TestimonialCard key={item.key} name={item.name} image={item.image} quote={item.quote} />
        ))}
      </Container>
    </section>
  )
}

// Section Learn
function LearnSection() {
  const { t } = useTranslation(["landing", "urls"])

  return (
    <section className={styles.learn}>
      <h2 className={styles.heroH2}>{t("learn.title")}</h2>
      <p className={styles.heroDescription}>{t("learn.description")}</p>
      <Container grid={3} inner>
        <GuideCard
          imageSrc="https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d7d4d2b5e570ce97fce03f_1988196_256429-P4R90V-522.svg"
          title={t("learn.guides.title")}
          description={t("learn.guides.description")}
          link={t("urls:guides.home")}
          buttonText={t("learn.guides.button")}
        />
        <GuideCard
          imageSrc="https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d7d4d3d72fa8bd83060538_2151572173.jpg"
          title={t("learn.medium.title")}
          description={t("learn.medium.description")}
          link={MEDIUM_URL}
          buttonText={t("learn.medium.button")}
        />
        <GuideCard
          imageSrc="https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67d7d4d367e7e098cde48d63_105340.jpg"
          title={t("learn.telegram.title")}
          description={t("learn.telegram.description")}
          link={TELEGRAM_URL}
          buttonText={t("learn.telegram.button")}
        />
      </Container>
    </section>
  )
}

// ─── LAYOUT ───────────────────────────────────

export function Component() {
  return (
    <div className={styles.landingContainer}>
      <Container>
        <HeroSection />
        <MobileExperienceSection />
        <DeFiActionsSection />
        <StatisticsSection />
        <FeaturesSection />
        <TestimonialsSection />
        <LearnSection />
      </Container>
    </div>
  )
}
