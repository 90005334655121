import { useCallback } from "react"
import { useLayout } from "../context"
import { DECIMALS_PERCENTAGE, DECIMALS_TOKEN_AMOUNT } from "../js/const/decimals"
import { formatAmount as jsFormatAmount, formatCurrencyAmount as jsFormatCurrencyAmount } from "../js/number"
import { CurrencyAmountSerialized } from "../domain/_kernel/CurrencyAmount"
import { TokenPriceList } from "../domain/token/Models/PriceList"

export const useFormatAmounts = () => {
  const { currencyID } = useLayout()

  const formatAmount = useCallback(
    (amount: number, max = DECIMALS_TOKEN_AMOUNT, min = 0) => {
      return jsFormatAmount({ number: Number(amount), min, max, currencyID })
    },
    [currencyID],
  )

  const formatCurrencyAmount = useCallback(
    (amount: number, min?: number, max?: number) => {
      const formattedNumber = Number(amount)
      return jsFormatCurrencyAmount({
        currencyID,
        number: formattedNumber,
        min,
        max,
      })
    },
    [currencyID],
  )

  const formatCurrencyAmounts = useCallback(
    (amounts: CurrencyAmountSerialized[] | undefined, min?: number, max?: number) => {
      const number = amounts?.find((amount) => amount.currencyID === currencyID)?.number || 0

      return jsFormatCurrencyAmount({
        currencyID,
        number,
        min,
        max,
      })
    },
    [currencyID],
  )

  const formatPercentage = useCallback(
    (amount: number) => {
      if (amount > 1000) {
        const formattedNumber = jsFormatAmount({ number: 1000, min: 0, max: 0, currencyID })
        return `>${formattedNumber}%`
      }

      const formattedNumber = jsFormatAmount({ number: amount, min: 0, max: DECIMALS_PERCENTAGE, currencyID })
      return `${formattedNumber}%`
    },
    [currencyID],
  )

  const getCurrencyAmount = useCallback(
    (amounts: CurrencyAmountSerialized[] | undefined) => {
      return amounts?.find((c) => c.currencyID === currencyID)?.number ?? 0
    },
    [currencyID],
  )

  const formatPrices = useCallback(
    (prices: TokenPriceList | undefined) => {
      const price = prices?.items.find((p) => p.currencyID === currencyID)?.value ?? 0
      return formatCurrencyAmount(price, 4, 4)
    },
    [currencyID],
  )

  return {
    getCurrencyAmount,
    formatAmount,
    formatCurrencyAmount,
    formatCurrencyAmounts,
    formatPercentage,
    formatPrices,
  }
}
