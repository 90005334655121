import { useEffect, useMemo, useState } from "react"
import { NotifyBar, InfoIcon } from "@tutellus/tutellus-components"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { isAppInstalled } from "../../../js/pwa"
import { useEthereum, useTransactions } from "../../../context"
import { SHOW_PWA_INFO } from "../../../js/const"

type BeforeInstallPromptEvent = Event & {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed"
    platform: string
  }>
  prompt(): Promise<void>
}

export const NotifyPWA = () => {
  const { t } = useTranslation(["pwa", "urls"])
  const { wallet } = useEthereum()
  const { transactions } = useTransactions()
  const [showPWAInfo, setShowPWAInfo] = useLocalStorage(SHOW_PWA_INFO, true)
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null)

  const isIntalled = useMemo(() => isAppInstalled(), [])

  useEffect(() => {
    if (!showPWAInfo) return

    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault()
      setDeferredPrompt(e)
    }

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt as EventListener)

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt as EventListener)
    }
  }, [showPWAInfo])

  if (isIntalled || !showPWAInfo || wallet.isInjected || transactions.isEmpty) {
    return
  }

  const handleClick = async () => {
    if (deferredPrompt) {
      await deferredPrompt.prompt()
      setDeferredPrompt(null)
    } else {
      window.location.href = t("guides.home", { ns: "urls" })
    }
  }

  const handleClose = () => {
    setShowPWAInfo(false)
  }

  const notificationText = deferredPrompt ? t("notifyBar.androidText") : t("notifyBar.iosText")
  const buttonText = deferredPrompt ? t("notifyBar.androidButton") : t("notifyBar.iosButton")

  return (
    <NotifyBar
      color="info"
      text={notificationText}
      icon={<InfoIcon />}
      onClick={handleClick}
      onClose={handleClose}
      button={buttonText}
    />
  )
}
