import { CHAINS } from "@tutellus/constants"
import { Chain } from "@tutellus/schemas"
import { Address } from "viem"

export const CHAIN_ID = import.meta.env.VITE_CHAIN_ID
export const CHAIN_CONFIG = Object.values(CHAINS).find((chain) => chain.id === Number(CHAIN_ID)) as Chain

const getExplorerURL = () => {
  const client = window.publicClient
  return client.chain?.blockExplorers?.default.url
}

export const getExplorerAddressURL = (address: Address) => {
  const explorerUrl = getExplorerURL()
  return `${explorerUrl}/address/${address}`
}

export const getExplorerTokenURL = (address: Address) => {
  const explorerUrl = getExplorerURL()
  return `${explorerUrl}/token/${address}`
}

export const getExplorerTransactionURL = (hash: string) => {
  const explorerUrl = getExplorerURL()
  return `${explorerUrl}/tx/${hash}`
}
