export const DATA_TEST_IDS = {
  REGISTER_HERE: "register-here",
  REGISTER_INPUT: "register-input",
  REGISTER_CHECKBOX: "register-checkbox",
  REGISTER_BUTTON: "register-button",
  REWARDS_BUTTON: "rewards-button",
  REWARDS_CLAIM_BUTTON: "rewards-claim-button",
  SWAP_CONTAINER: "swap-container",
  TOPBAR_CONTAINER: "topbar-container",
  NFT_SELECT: "nft-select",
  VOTE_SELECT: "VOTE_SELECT",
  VOTE_BOTTOM_BAR: "VOTE_BOTTOM_BAR",
  VOTE_BUTTON: "VOTE_BUTTON",
  VOTE_CARD: "VOTE_CARD",
}
