import {
  AddFilledIcon,
  AlertIcon,
  CheckOutlinedIcon,
  CloseCircleIcon,
  GiftIcon,
  LikeOutlineIcon,
  LockedIcon,
  MergeIcon,
  RemoveFilledIcon,
  Spinner,
  SplitIcon,
  SwapIcon,
  SwitchCircularIcon,
  UnlockedIcon,
  VoteIcon,
} from "@tutellus/tutellus-components"
import { Transaction, TransactionStatus, TransactionType } from "../../../domain/ethereum/Models/Transaction"

const STATUS_ICONS: Record<TransactionStatus, React.JSX.Element> = {
  [Transaction.STATUS.PENDING]: <Spinner />,
  [Transaction.STATUS.SUCCESS]: <CheckOutlinedIcon />,
  [Transaction.STATUS.REVERTED]: <CloseCircleIcon />,
  [Transaction.STATUS.EXPIRED]: <AlertIcon />,
}

export const getStatusIcon = (status: TransactionStatus) => {
  return STATUS_ICONS[status]
}

const TRANSACTION_ICONS: Record<TransactionType, React.JSX.Element> = {
  [Transaction.TYPES.ADD_INCENTIVE]: <AddFilledIcon />,
  [Transaction.TYPES.ADD_LIQUIDITY]: <AddFilledIcon />,
  [Transaction.TYPES.APPROVE]: <LikeOutlineIcon />,
  [Transaction.TYPES.CLAIM_POOL]: <GiftIcon />,
  [Transaction.TYPES.CLAIM_REWARD]: <GiftIcon />,
  [Transaction.TYPES.CLAIM_SYNTHETIC]: <GiftIcon />,
  [Transaction.TYPES.CLAIM]: <GiftIcon />,
  [Transaction.TYPES.CLOSE_EPOCH]: <CloseCircleIcon />,
  [Transaction.TYPES.DEPOSIT_STAKE]: <AddFilledIcon />,
  [Transaction.TYPES.DEPOSIT]: <AddFilledIcon />,
  [Transaction.TYPES.INCREASE_STAKE]: <AddFilledIcon />,
  [Transaction.TYPES.MERGE_NFT]: <MergeIcon />,
  [Transaction.TYPES.MIGRATE]: <SwitchCircularIcon />,
  [Transaction.TYPES.REBASE]: <SwitchCircularIcon />,
  [Transaction.TYPES.REBUILD_STAKE]: <VoteIcon />,
  [Transaction.TYPES.SPLIT_NFT]: <SplitIcon />,
  [Transaction.TYPES.STAKE_LIQUIDITY]: <LockedIcon />,
  [Transaction.TYPES.SWAP]: <SwapIcon />,
  [Transaction.TYPES.TRANSFER]: <AddFilledIcon />,
  [Transaction.TYPES.UNSTAKE_LIQUIDITY]: <UnlockedIcon />,
  [Transaction.TYPES.VOTE]: <VoteIcon />,
  [Transaction.TYPES.WITHDRAW_LIQUIDITY]: <RemoveFilledIcon />,
  [Transaction.TYPES.WITHDRAW_STAKE]: <RemoveFilledIcon />,
  [Transaction.TYPES.WITHDRAW]: <RemoveFilledIcon />,
}

export const getTypeIcon = (type: TransactionType) => {
  return TRANSACTION_ICONS[type]
}
