export enum ErrorCodes {
  USER_LOGIN_NOT_VERIFIED = "USER_LOGIN_NOT_VERIFIED",
  WALLET_NOT_SUPPORTED = "WALLET_NOT_SUPPORTED",

  // TRANSACTION ERRORS
  USER_REJECTED_TRANSACTION = "USER_REJECTED_TRANSACTION",
  TRANSACTION_ERROR = "TRANSACTION_ERROR",

  // SIGNATURE ERRORS
  USER_REJECTED_SIGNATURE = "USER_REJECTED_SIGNATURE",
  SIGNATURE_ERROR = "SIGNATURE_ERROR",
}
