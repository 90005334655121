import { Outlet } from "react-router"
import { DataContext } from "../../context"

export function Component() {
  return (
    <DataContext>
      <Outlet />
    </DataContext>
  )
}
