import { useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import {
  BottomNavbar,
  DashboardIcon,
  SettingsIcon,
  SwapIcon,
  WalletIcon,
  DocIcon,
  TradeIcon,
  VoteIcon,
  BuyIcon,
  YieldIcon,
  InfoIcon,
  StakeIcon,
  RewardsIcon,
  CoinIcon,
  NFTIcon,
  StatsIcon,
  GT3OutlineIcon,
  PoolIcon,
  TransactionIcon,
} from "@tutellus/tutellus-components"
import { SettingsModal } from "../../components/modules/SettingsModal"
import { TokenModal } from "../../components/modules/TokenModal"
import { WalletModal } from "../../components/modules/WalletModal"
import { RewardsModal } from "../../components/modules/RewardsModal"
import { Topbar } from "../../components/modules/Topbar"
import { Footer } from "../../components/modules/Footer"
import { useTransactions } from "../../context"
import { useRewards } from "../../hooks/useRewards"
import { NotifyPWA } from "../../components/modules/NotifyPWA"
import styles from "./index.module.css"
import { GITBOOK_URL } from "../../js/const/urls"

export function Component() {
  const { pathname: currentRoute } = useLocation()
  const { t } = useTranslation(["common", "synthetics"])
  const navigate = useNavigate()
  const { transactions } = useTransactions()
  const { formattedRewards, totalRewards, formattedTotalRewards } = useRewards()

  const [showSettings, setShowSettings] = useState(false)
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [showRewardsModal, setShowRewardsModal] = useState(false)
  const [showWalletModal, setShowWalletModal] = useState(false)

  const handleRouteChange = (url: string) => {
    if (url.startsWith("http")) {
      window.open(url, "_blank")
    } else {
      navigate(url)
    }
  }
  const menuItemsBottom = [
    {
      label: t("buy"),
      tooltip: t("buy"),
      description: t("buyMobileDescription"),
      url: "/buy",
      icon: BuyIcon,
      tag: t("new"),
    },
    {
      label: t("swap"),
      tooltip: t("swap"),
      description: t("swapMobileDescription"),
      url: "/swap",
      icon: SwapIcon,
    },
    {
      label: t("liquidity"),
      tooltip: t("liquidity"),
      description: t("liquidityMobileDescription"),
      icon: CoinIcon,
      url: "/liquidity",
    },
    {
      label: t("vote"),
      tooltip: t("vote"),
      description: t("voteMobileDescription"),
      icon: VoteIcon,
      url: "/vote",
    },
    {
      label: t("wallet"),
      tooltip: t("wallet"),
      description: t("walletMobileDescription"),
      url: "/wallet",
      icon: WalletIcon,
    },
    {
      label: t("explore"),
      tooltip: t("explore"),
      description: t("exploreDescription"),
      url: "/explore/tokens",
      icon: StatsIcon,
    },
    {
      label: t("dashboard"),
      description: t("dashboardDescription"),
      url: "/dashboard",
      icon: DashboardIcon,
    },
    {
      label: t("nfts"),
      tooltip: t("nfts"),
      description: t("nftsDescription"),
      url: "/nfts/info",
      icon: NFTIcon,
    },
    {
      label: t("docs"),
      tooltip: t("docs"),
      description: t("docsDescription"),
      icon: DocIcon,
      url: GITBOOK_URL,
    },
    {
      label: t("settings"),
      tooltip: t("settings"),
      description: t("settingsDescription"),
      icon: SettingsIcon,
      onClick: () => setShowSettings(true),
    },
    // {
    //   label: t("various"),
    //   description: t("variousDescription"),
    //   icon: SettingsIcon,
    //   items: [
    //     {
    //       label: t("guides"),
    //       description: t("guidesDescription"),
    //       icon: WalletIcon,
    //       url: DOCS,
    //       onClick: () => window.open(DOCS), "_blank"),
    //     },
    //     {
    //       label: t("nfts"),
    //       tooltip: t("nfts"),
    //       description: t("nftsDescription"),
    //       url: "/nfts",
    //       icon: NFTIcon,
    //     },
    //     {
    //       label: t("docs"),
    //       tooltip: t("docs"),
    //       description: t("docsDescription"),
    //       icon: DocIcon,
    //       url: DOCS,
    //     },
    //     {
    //       label: t("settings"),
    //       tooltip: t("settings"),
    //       description: t("settingsDescription"),
    //       icon: SettingsIcon,
    //       onClick: () => setShowSettings(true),
    //     },
    //   ],
    // },
  ]

  const menuItems = [
    {
      label: t("dashboard"),
      tooltip: t("dashboard"),
      icon: DashboardIcon,
      items: [
        {
          label: t("dashboard"),
          tooltip: t("dashboard"),
          description: t("dashboardDescription"),
          url: "/dashboard",
          icon: DashboardIcon,
        },
      ],
    },
    {
      label: t("operate"),
      tooltip: t("operate"),
      description: t("operateDescription"),
      icon: TradeIcon,
      tag: t("new"),
      items: [
        {
          label: t("buy"),
          tooltip: t("buy"),
          description: t("buyDescription"),
          url: "/buy",
          icon: BuyIcon,
          tag: t("new"),
        },
        {
          label: t("swap"),
          tooltip: t("swap"),
          description: t("swapDescription"),
          url: "/swap",
          icon: SwapIcon,
        },
      ],
    },
    {
      label: t("liquidity"),
      tooltip: t("liquidity"),
      description: t("liquidityDescription"),
      icon: CoinIcon,
      items: [
        {
          label: t("pools"),
          tooltip: t("pools"),
          description: t("poolsDescription"),
          url: "/liquidity",
          icon: YieldIcon,
        },
      ],
    },
    {
      label: t("vote"),
      tooltip: t("vote"),
      description: t("voteDescription"),
      icon: VoteIcon,
      items: [
        {
          label: t("info"),
          tooltip: t("info"),
          description: t("infoDescription"),
          url: "/vote/information",
          icon: InfoIcon,
        },
        {
          label: t("generate"),
          tooltip: t("generate"),
          description: t("generateDescription"),
          url: "/vote/generate",
          icon: StakeIcon,
        },
        {
          label: t("voting"),
          tooltip: t("voting"),
          description: t("votingDescription"),
          url: "/vote/voting",
          icon: VoteIcon,
        },
        {
          label: t("rewards"),
          tooltip: t("rewards"),
          description: t("rewardsDescription"),
          url: "/vote/rewards",
          icon: RewardsIcon,
        },
      ],
    },
    {
      label: t("explore"),
      tooltip: t("explore"),
      description: t("exploreDescription"),
      icon: StatsIcon,
      items: [
        {
          label: t("exploreTokens"),
          tooltip: t("exploreTokens"),
          description: t("exploreTokensDescription"),
          url: "/explore/tokens",
          icon: GT3OutlineIcon,
        },
        {
          label: t("explorePools"),
          tooltip: t("explorePools"),
          description: t("explorePoolsDescription"),
          url: "/explore/pools",
          icon: PoolIcon,
        },
        {
          label: t("exploreTransactions"),
          tooltip: t("exploreTransactions"),
          description: t("exploreTransactionsDescription"),
          url: "/explore/transactions",
          icon: TransactionIcon,
        },
      ],
    },
    {
      label: t("more"),
      tooltip: t("more"),
      icon: SettingsIcon,
      items: [
        {
          label: t("wallet"),
          tooltip: t("wallet"),
          description: t("walletDescription"),
          url: "/wallet",
          icon: WalletIcon,
        },
        {
          label: t("nfts"),
          tooltip: t("nfts"),
          description: t("nftsDescription"),
          url: "/nfts",
          icon: NFTIcon,
        },
        {
          label: t("docs"),
          tooltip: t("docs"),
          description: t("docsDescription"),
          icon: DocIcon,
          onClick: () => window.open(GITBOOK_URL, "_blank"),
        },
        {
          label: t("settings"),
          tooltip: t("settings"),
          description: t("settingsDescription"),
          icon: SettingsIcon,
          onClick: () => setShowSettings(true),
        },
      ],
    },
  ]

  const bottomItems = [
    {
      label: t("buy"),
      url: "/buy",
      icon: BuyIcon,
    },
    {
      label: t("swap"),
      url: "/swap",
      icon: SwapIcon,
    },
    {
      label: t("generate"),
      icon: YieldIcon,
      url: "/liquidity",
    },
    {
      label: t("vote"),
      url: "/vote/generate",
      icon: VoteIcon,
    },
  ]

  return (
    <>
      <div className={styles.layoutContainer}>
        <header className={styles.layoutHeader}>
          <Topbar
            pendingTransactions={transactions.pending}
            menuItems={menuItems}
            currentRoute={currentRoute}
            totalRewards={totalRewards}
            formattedTotalRewards={formattedTotalRewards}
            onShowTokenModal={() => setShowTokenModal(true)}
            onShowRewardsModal={() => setShowRewardsModal(true)}
            onShowWalletModal={() => setShowWalletModal(true)}
          />
        </header>
        <div className={styles.layoutNotify}>
          <NotifyPWA />
        </div>
        <main className={styles.layoutMain}>
          <Outlet />
        </main>
        <footer className={styles.layoutFooter}>
          <Footer />
          <div className={styles.bottomNavBar}>
            <BottomNavbar
              menuItems={menuItemsBottom}
              bottomItems={bottomItems}
              currentRoute={currentRoute}
              onRouteChange={handleRouteChange}
              t={undefined}
              onOpenChange={undefined}
            />
          </div>
        </footer>
        {showRewardsModal && (
          <RewardsModal rewards={formattedRewards} onClose={() => setShowRewardsModal(!showRewardsModal)} />
        )}
        {showSettings && <SettingsModal onClose={() => setShowSettings(!showSettings)} />}
        {showTokenModal && <TokenModal onClose={() => setShowTokenModal(false)} />}
        {showWalletModal && <WalletModal onClose={() => setShowWalletModal(false)} />}
      </div>
    </>
  )
}
