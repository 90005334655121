import { LoaderFunctionArgs, redirect } from "react-router"
import { WalletState } from "../../domain/ethereum/Models/WalletState"

export type PoolDepositLoaderData = { poolReservesIDs: string[] }

export const poolDepositLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const { poolID } = params as { poolID: string }

  const walletState = await window.domain.GetWalletStateEthereumUseCase.execute()

  if (walletState.status !== WalletState.STATUS.CONNECTED) {
    const url = new URL(request.url)
    const currentPath = url.pathname

    if (currentPath) {
      return redirect(`/connect?redirectTo=${encodeURIComponent(currentPath)}`)
    } else {
      return redirect("/connect")
    }
  }

  const projectConfig = await window.domain.GetConfigProjectUseCase.execute()
  const poolReservesIDs = projectConfig.pools.find((pool) => pool.id === poolID)?.tokens
  if (!poolReservesIDs) throw new Error("Missing poolReservesIDs")

  return { poolReservesIDs } satisfies PoolDepositLoaderData
}
