import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useData } from "../context/DataContext"
import { useLayout } from "../context"
import { RewardModalItem } from "../components/modules/RewardsModal"
import { CHAIN_CONFIG } from "../js/chain"
import { useFormatAmounts } from "./useFormatAmounts"
import { TransactionType } from "../domain/ethereum/Models/Transaction"

const PROJECT_KEY = import.meta.env.VITE_PROJECT_KEY
const FUSDT_AMOUNT = 100

export const useRewards = () => {
  const { userAirdropRewards, userPoolStatsList } = useData()
  const { formatAmount, formatCurrencyAmount } = useFormatAmounts()
  const { currencyID } = useLayout()
  const { t } = useTranslation("common")

  const formattedRewards = useMemo(() => {
    const rewards = [] as Array<RewardModalItem>

    // Airdrops
    userAirdropRewards?.allocation.forEach((reward) => {
      const { name, available, earned } = reward
      if (!available.number && !earned.number) return

      const pendingCurrency = available.currencyAmounts!.find((amount) => amount.currencyID === currencyID)!.formatted
      const earnedCurrency = earned.currencyAmounts!.find((amount) => amount.currencyID === currencyID)!.formatted

      rewards.push({
        name,
        pending: formatAmount(available.number),
        pendingCurrency,
        earned: formatAmount(earned.number),
        earnedCurrency,
        rewardIcon: earned.tokenID,
        type: TransactionType.CLAIM_REWARD,
        onClaim: () => window.domain.ClaimAllocationRewardUserUseCase.execute({ reward }),
      })
    })

    userAirdropRewards?.merkle.forEach((reward) => {
      const { name, available, distributed } = reward
      if (!available.number && !distributed.number) return

      const pendingCurrency = available.currencyAmounts!.find((amount) => amount.currencyID === currencyID)!.formatted
      const earnedCurrency = distributed.currencyAmounts!.find((amount) => amount.currencyID === currencyID)!.formatted

      rewards.push({
        name,
        pending: formatAmount(available.number),
        pendingCurrency,
        earned: formatAmount(distributed.number),
        earnedCurrency,
        rewardIcon: distributed.tokenID,
        type: TransactionType.CLAIM_REWARD,
        onClaim: () => window.domain.ClaimMerkleRewardUserUseCase.execute({ reward }),
      })
    })

    // Liquidity
    const totalLiquidity = userPoolStatsList?.items.reduce(
      (acc, { rewards: poolRewards }) => {
        const pendingReward = poolRewards.pending
        const pendingToken = pendingReward.tokens[0].number || 0
        const pendingCurrency = pendingReward.total?.find((amount) => amount.currencyID === currencyID)?.number || 0

        const earnedReward = poolRewards.earned
        const earnedToken = earnedReward.tokens[0].number || 0
        const earnedCurrency = earnedReward.total?.find((amount) => amount.currencyID === currencyID)?.number || 0

        return {
          pending: acc.pending + pendingToken,
          pendingCurrency: acc.pendingCurrency + pendingCurrency,
          earned: acc.earned + earnedToken,
          earnedCurrency: acc.earnedCurrency + earnedCurrency,
        }
      },
      { pending: 0, pendingCurrency: 0, earned: 0, earnedCurrency: 0 },
    )

    if (totalLiquidity?.earned) {
      rewards.push({
        name: t("liquidity"),
        pending: formatAmount(totalLiquidity.pending),
        pendingCurrency: formatCurrencyAmount(totalLiquidity.pendingCurrency),
        earned: formatAmount(totalLiquidity.earned),
        earnedCurrency: formatCurrencyAmount(totalLiquidity.earnedCurrency),
        rewardIcon: PROJECT_KEY,
        type: TransactionType.CLAIM_POOL,
        onClaim: () => window.domain.ClaimAllRewardsPoolUseCase.execute(),
      })
    }

    if (CHAIN_CONFIG.isTestnet) {
      const amount = FUSDT_AMOUNT
      const tokenID = "FUSDT"
      const pendingCurrency = formatCurrencyAmount(amount)

      rewards.push({
        name: t("mint"),
        pending: formatAmount(amount),
        pendingCurrency,
        earned: formatAmount(0),
        earnedCurrency: formatCurrencyAmount(0),
        rewardIcon: tokenID,
        type: TransactionType.CLAIM_REWARD,
        onClaim: () => window.domain.ClaimFUSDTRewardUserUseCase.execute({ amount: amount.toString() }),
      })
    }

    return rewards
  }, [
    userAirdropRewards?.allocation,
    userAirdropRewards?.merkle,
    userPoolStatsList?.items,
    formatAmount,
    currencyID,
    t,
    formatCurrencyAmount,
  ])

  const totalRewards = useMemo(() => {
    let total = 0

    userAirdropRewards?.allocation.forEach(({ available }) => {
      const currencyAmount = available.currencyAmounts!.find((amount) => amount.currencyID === currencyID)!.number
      total += currencyAmount
    })

    userAirdropRewards?.merkle.forEach(({ available }) => {
      const currencyAmount = available.currencyAmounts!.find((amount) => amount.currencyID === currencyID)!.number
      total += currencyAmount
    })

    userPoolStatsList?.items.forEach(({ rewards }) => {
      const currencyAmount = rewards.pending.total.find((amount) => amount.currencyID === currencyID)!.number
      total += currencyAmount
    })

    if (CHAIN_CONFIG.isTestnet) {
      total += FUSDT_AMOUNT
    }

    return total
  }, [userAirdropRewards, userPoolStatsList, currencyID])

  const formattedTotalRewards = formatCurrencyAmount(totalRewards)

  return { formattedRewards, totalRewards, formattedTotalRewards }
}
