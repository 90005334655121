import { Button, Card, Heading, Modal, TokenIcon } from "@tutellus/tutellus-components"
import styles from "./index.module.css"
import { useTranslation } from "react-i18next"
import { useTransactions } from "../../../context/TransactionsContext"
import { TransactionType } from "../../../domain/ethereum/Models/Transaction"
import { DATA_TEST_IDS } from "../../../../e2e/config/ids"

export interface RewardModalItem {
  name: string
  type: TransactionType
  rewardIcon: string
  pending: string
  pendingCurrency: string
  earned: string
  earnedCurrency: string
  onClaim: () => void
}

interface RewardsModalProps {
  rewards: RewardModalItem[]
  onClose: () => void
}

export const RewardsModal = ({ onClose, rewards }: RewardsModalProps) => {
  const { t } = useTranslation("common")
  const { transactions, submittedTransactionType } = useTransactions()

  return (
    <Modal onClose={onClose} title={t("rewards")} isOpen>
      <div className={styles.cardsContainer}>
        {rewards.map(
          ({ name, rewardIcon, pending, pendingCurrency, earned, earnedCurrency, type: rewardType, onClaim }) => {
            const isSubmitting = submittedTransactionType === rewardType
            const isLoading = transactions.pending.some((transaction) => transaction.type === rewardType)
            const isDisabled = pending === "0"

            return (
              <Card key={rewardType} border="gray">
                <Heading as="h2">{name}</Heading>
                <div className={styles.cardColumn}>
                  <div>{t("pending")}: </div>
                  <div className={styles.rewardsColumn}>
                    <span className={styles.rewardsToken}>
                      {pending} <TokenIcon iconKey={rewardIcon} size="m" />
                    </span>
                    <span className={styles.rewardsCurrency}>{pendingCurrency}</span>
                  </div>
                </div>
                <div className={styles.cardColumn}>
                  <div>
                    <span>{t("totalEarned")}: </span>
                  </div>
                  <div className={styles.rewardsColumn}>
                    <span className={styles.rewardsToken}>
                      {earned} <TokenIcon iconKey={rewardIcon} size="m" />
                    </span>
                    <span className={styles.rewardsCurrency}>{earnedCurrency}</span>
                  </div>
                </div>

                <Button
                  isFull
                  onClick={onClaim}
                  isDisabled={isDisabled}
                  isLoading={isSubmitting || isLoading}
                  data-testid={DATA_TEST_IDS.REWARDS_CLAIM_BUTTON}
                >
                  {t("claim")}
                </Button>
              </Card>
            )
          },
        )}
      </div>
    </Modal>
  )
}
