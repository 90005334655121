import { useTranslation } from "react-i18next"
import { Button, Heading, Modal, type ModalProps } from "@tutellus/tutellus-components/components"
import socialModal from "../../../../assets/socialModal.webp"
import styles from "./index.module.css"
import { modals } from "../../../../../locales/en/common.json"
import { useShare } from "../../../../hooks/useShare"
export interface SocialModalProps extends Pick<ModalProps, "width" | "onClose" | "isOpen"> {
  variant: keyof typeof modals.social
}

export const SocialModal = ({ width, onClose, isOpen, variant }: SocialModalProps): React.JSX.Element => {
  const { t } = useTranslation("common", { keyPrefix: `modals.social.${variant}` })
  const { shareOnX } = useShare()

  const hanldeShare = () => {
    shareOnX(t("share.x.description"), { via: t("share.x.via") })
  }

  return (
    <Modal title={t("title")} width={width} onClose={onClose} isOpen={isOpen}>
      <div className={styles.container}>
        <img src={socialModal} alt="NFT" />
        <Heading color="gray-soft">{t("description")}</Heading>
        <div className={styles.actionsContainer}>
          <Button onClick={onClose} type="outline">
            {t("actions.close")}
          </Button>
          <Button onClick={hanldeShare}>{t("actions.share")}</Button>
        </div>
      </div>
    </Modal>
  )
}
