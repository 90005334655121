import cx from "classnames"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { TokenTypeEnum } from "@tutellus/core-contracts-ifaces"
import { Modal, AddressBox, Button, Tabs, Tab, Select } from "@tutellus/tutellus-components"
import { Link, useRouteLoaderData } from "react-router"
import { useEthereum, useTransactions } from "../../../context"
import { Transaction } from "../../../domain/ethereum/Models/Transaction"
import { WalletState } from "../../../domain/ethereum/Models/WalletState"
import { CHAIN_CONFIG } from "../../../js/chain"
import { RootDataLoader } from "../../../pages/DataRoot/loader"
import { getStatusIcon, getTypeIcon } from "./utils"
import styles from "./index.module.css"

enum TabTypes {
  Transactions = "transactions",
  Transfer = "transfer",
}

interface WalletModalProps {
  onClose: () => void
}

const EXTERNAL_TOKEN_ID = "EXTERNAL_TOKEN"

export const WalletModal = ({ onClose }: WalletModalProps) => {
  const { t } = useTranslation("common")
  const { projectConfig } = useRouteLoaderData("root") as RootDataLoader
  const { wallet, disconnectWallet } = useEthereum()
  const { transactions, cleanTransactions } = useTransactions()

  const [selectedTab, setSelectedTab] = useState<TabTypes>(TabTypes.Transactions)
  const [addressTo, setAddressTo] = useState<string>("")
  const [tokenID, setTokenID] = useState<string>("")
  const [amount, setAmount] = useState<string>("")
  const [externalTokenAddress, setExternalTokenAddress] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const iconWallet = wallet.connector?.icon
  const availableTokens = projectConfig.tokens
    .filter((token) => token.type === TokenTypeEnum.ERC20)
    .sort((a, b) => a.symbol.localeCompare(b.symbol))

  useEffect(() => {
    if (availableTokens.length > 0 && !tokenID) {
      setTokenID(availableTokens[0].id)
    }
  }, [availableTokens, tokenID])

  const handleTabChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(e.target.value as TabTypes)
  }

  const handleOnTransfer = async () => {
    setIsLoading(true)

    if (tokenID === EXTERNAL_TOKEN_ID) {
      window.domain.TransferExternalTokensEthereumUseCase.execute({
        tokenAddress: externalTokenAddress,
        amount,
        to: addressTo,
      })
        .then(() => {
          setIsLoading(false)
          setAddressTo("")
          setTokenID("")
          setAmount("")
          setExternalTokenAddress("")
        })
        .finally(() => setIsLoading(false))
    } else {
      await window.domain.TransferTokensEthereumUseCase.execute({ tokenID, amount, to: addressTo })
        .then(() => {
          setIsLoading(false)
          setAddressTo("")
          setTokenID("")
          setAmount("")
          setExternalTokenAddress("")
        })
        .finally(() => setIsLoading(false))
    }
  }

  const selectTokenOptions = [
    ...availableTokens.map((token) => ({ value: token.id, label: token.symbol })),
    { value: EXTERNAL_TOKEN_ID, label: t("other") },
  ]

  const hasTransactions = transactions.items.length > 0

  return (
    <Modal
      onClose={onClose}
      title={wallet.type === WalletState.TYPES.ABSTRACTED ? t("myHumanWallet") : t("yourAccount")}
    >
      <div className={styles.container}>
        <div className={styles.tutBox}>
          <div className={styles.imageWallet}>
            <img src={iconWallet} alt="Wallet icon" />
          </div>
          <div className={styles.addressBox}>
            <div className={styles.chainInfo}>
              <img className={styles.chainLogo} src={CHAIN_CONFIG.logo} alt={CHAIN_CONFIG.name} />
              <p>{CHAIN_CONFIG.name}</p>
            </div>
            <AddressBox account={wallet.account || ""} url={false} />
            <Link target="_blank" to={wallet.explorerUrl}>
              {t("viewInExplorer")}
            </Link>
          </div>
        </div>

        {(wallet.type === WalletState.TYPES.ABSTRACTED && (
          <div className={styles.tabsContainer}>
            <Tabs>
              <Tab
                label={t("transactions.title")}
                value={TabTypes.Transactions}
                onChange={handleTabChange}
                checked={selectedTab === TabTypes.Transactions}
              />
              <Tab
                label={t("send")}
                value={TabTypes.Transfer}
                onChange={handleTabChange}
                checked={selectedTab === TabTypes.Transfer}
              />
            </Tabs>
          </div>
        )) || <h3 className={styles.type}>{t("transactions.title")}</h3>}

        {selectedTab === TabTypes.Transactions && (
          <>
            <div className={styles.transactionsBox}>
              {!hasTransactions ? (
                <p className={styles.noTransactions}>{t("noTransactions")}</p>
              ) : (
                <div className={styles.transactionsList}>
                  {transactions.items
                    .sort((a, b) => b.date!.getTime() - a.date!.getTime())
                    .map((transaction) => (
                      <div
                        className={cx({
                          [styles.pending]:
                            transaction.status === Transaction.STATUS.PENDING ||
                            transaction.status === Transaction.STATUS.EXPIRED,
                          [styles.success]: transaction.status === Transaction.STATUS.SUCCESS,
                          [styles.error]: transaction.status === Transaction.STATUS.REVERTED,
                        })}
                        key={transaction.hash || transaction.userOpHash}
                        onClick={() => window.open(transaction.explorerUrl, "_blank")}
                      >
                        <span className={styles.typeIcon}>{getTypeIcon(transaction.type)}</span>
                        <div className={styles.typeBox}>
                          <span className={styles.type}>{t(`transactions.types.${transaction.type}`)}</span>
                          <span>{transaction.date?.toLocaleString()}</span>
                        </div>
                        <span className={styles.state}>{getStatusIcon(transaction.status)}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className={styles.footer}>
              <Button type="outline" onClick={cleanTransactions} isDisabled={!hasTransactions} isFull>
                {t("clean")}
              </Button>
              <Button
                onClick={() => {
                  disconnectWallet()
                  onClose()
                }}
                isFull
              >
                {t("disconnect")}
              </Button>
            </div>
          </>
        )}

        {selectedTab === TabTypes.Transfer && (
          <div>
            <div className={styles.card}>
              <label>{t("address")}</label>
              <input
                placeholder={t("address")}
                className={styles.input}
                id="addressTo"
                value={addressTo}
                onChange={(e) => setAddressTo(e.target.value)}
              />
            </div>
            <div className={styles.card}>
              <Select
                label="Token"
                id="tokenId"
                value={tokenID}
                options={selectTokenOptions}
                onChange={(e) => setTokenID(e.target.value)}
              />
              {tokenID === EXTERNAL_TOKEN_ID && (
                <div className={styles.mt2}>
                  <input
                    className={styles.input}
                    placeholder={t("address")}
                    id="externalTokenAddress"
                    value={externalTokenAddress}
                    onChange={(e) => setExternalTokenAddress(e.target.value)}
                  />
                </div>
              )}
              <label className={styles.mt2}>{t("quantity")}</label>
              <input
                className={styles.input}
                placeholder={t("quantity")}
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <div className={styles.mt2}>
                <Button isLoading={isLoading} isFull onClick={handleOnTransfer}>
                  {t("send")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
