export const GT3_LOGO =
  "https://cdn.prod.website-files.com/65f861746098e48e9c7538b0/672b970ea733a82db5bc5c0a_gt3-iso.svg"

export const GT3_DESKTOP_LOGO_DARK =
  "https://cdn.prod.website-files.com/65f861746098e48e9c7538b0/672b970efbbb70ae571d9330_gt3-dark.svg"

export const GT3_DESKTOP_LOGO_LIGHT =
  "https://cdn.prod.website-files.com/65f861746098e48e9c7538b0/672b970ec53ac8ae7b1db07b_gt3-light.svg"

export const GT3_MOBILE_LOGO_DARK =
  "https://cdn.prod.website-files.com/65f861746098e48e9c7538b0/672b970ecd60277e0891d3c6_gt3-text-dark.svg"

export const GT3_MOBILE_LOGO_LIGHT =
  "https://cdn.prod.website-files.com/65f861746098e48e9c7538b0/672b97106b83235d7f01c2ac_gt3-text-light.svg"

export const GT3_TOKEN_LOGO =
  "https://cdn.prod.website-files.com/66f543aba3eac7d7e5eb4643/67c1bfd9f5bd11477208aaf6_token.svg"
